<template>
  <div class="no-padding">
    <b-input
      v-model="computedValue"
      placeholder="Cari"
      maxlength="40"
      :has-counter="false"
      autocomplete="off"
    ></b-input>
  </div>
</template>


<script>
export default {
  name: "CariInput",
  props: { value: String },
  data() {
    return {
      valueData: this.value
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.valueData;
      },
      set(value) {
        this.valueData = value;
        this.$emit("input", value);
      }
    }
  },
  watch: {
    // When v-model is changed: update internal value
    value(value) {
      this.valueData = value;
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 480px) {
  .filter1 {
    flex: none;
    width: 14rem;
  }

  .filter2 {
    flex: none;
    width: 11rem;
  }

  .filter3 {
    flex: none;
    width: 8rem;
  }
}

.no-padding {
  margin: 0 5px;
}
</style>